<template>
 <PageHeading
  :has-meta-data="false"
  :has-action-button="true"
  :has-search="true"
  :title="$t('Expert stats')"
  @open="this.openForm = !this.openForm"
  @update:search="$emit('update:search', $event)"
  :hasSearch="false" />
 <div :key="form" class="">
  <h1 class="sr-only">Account Settings</h1>
  <header class="border-b w-full">
   <!-- Secondary navigation -->
   <nav class="flex overflow-x-auto py-4 max-w-5xl mx-auto">
    <ul
     role="list"
     class="flex flex-none gap-x-6 px-4 text-sm font-semibold leading-6 text-gray-700 sm:px-6 lg:px-8 items-center">
     <li v-for="item in navigation" :key="item.name">
      <router-link
       :to="`/expert-statistics/configuration/${item.href}`"
       :class="[
        item.current ? 'text-white bg-teal-600' : '',
        'px-2 py-1 rounded-lg hover:bg-teal-600 hover:text-white',
       ]"
       >{{ $t(item.name) }}</router-link
      >
     </li>
    </ul>
   </nav>
  </header>
  <div class="max-w-5xl mx-auto divide-y divide-white/5 pb-5">
   <form @submit.prevent="sendForm" method="POST">
    <div v-if="menu === 'groups'" class="py-16 space-y-8 px-4 sm:px-6 lg:px-8">
     <div
      v-for="item in navigation_groups"
      :key="item.name"
      class="grid grid-cols-1 md:grid-cols-3">
      <router-link :to="`/expert-statistics/groups/${item.href}`">
       <h2 class="text-base font-semibold leading-7">
        Configurer un groupe {{ item.name === "utilisateurs" ? "d'" : "de " }}{{ item.name }}
       </h2>
      </router-link>
      <!-- <p class="mt-1 text-sm leading-6 text-gray-400">
       Sélectionnez le type de groupe à 
      </p> -->
     </div>

     <!-- <div class="md:col-span-2">
      <div class="grid grid-cols-1 gap-x-6 sm:max-w-xl sm:grid-cols-6">
       <div class="col-span-full">
        <nav class="overflow-x-auto max-w-5xl mx-auto">
         <ul
          role="list"
          class="flex-none space-y-4 px-4 text-sm font-semibold text-gray-700 sm:px-6 lg:px-8 items-center">
          <li v-for="item in navigation_groups" :key="item.name">
           <router-link :to="`/expert-statistics/groups/${item.href}`">{{
            item.name
           }}</router-link>
          </li>
         </ul>
        </nav>
       </div>
      </div>
     </div> -->
    </div>
    <div
     v-if="menu === 'pbx'"
     class="grid grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
     <div>
      <h2 class="text-base font-semibold leading-7">Domaine PBX</h2>
      <p class="mt-1 text-sm leading-6 text-gray-400">
       Configuration du domaine par défaut
      </p>
     </div>

     <div class="md:col-span-2">
      <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
       <div class="col-span-full">
        <!-- <label class="block text-sm font-medium leading-6">PBX Host</label> -->
        <div class="">
         <div v-if="hostNames" class="flex items-center text-sm">
          <!-- <font-awesome-icon
          icon="server"
          class="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-600" /> -->
          <select
           required=""
           class="block w-full rounded-md py-1.5 shadow-sm focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 [&_*]:text-black"
           v-model="form.host_name">
           <option v-for="host in hostNames" :key="host" :value="host">
            {{ host }}
           </option>
          </select>
         </div>

         <div
          v-else
          class="mt-2 flex items-center text-sm text-gray-600 font-medium">
          <!-- <font-awesome-icon
          icon="server"
          class="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-600" /> -->
          <!-- {{ selectHostname ? this.selectHostname : "No 3CX Host" }} -->
          <select
           class="block w-full rounded-md py-1.5 shadow-sm focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 [&_*]:text-black">
           <option>
            {{ $t("noHostName") }}
           </option>
          </select>
         </div>
         <!-- <select
         id="timezone"
         name="timezone"
         class="block w-full rounded-md border-0 bg-white/5 py-1.5 shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 [&_*]:text-black">
         <option>Pacific Standard Time</option>
         <option>Eastern Standard Time</option>
         <option>Greenwich Mean Time</option>
        </select> -->
        </div>
       </div>
      </div>
     </div>
    </div>
    <div
     v-if="menu === 'report-table'"
     class="grid grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
     <div>
      <h2 class="text-base font-semibold leading-7">Intervalles de temps</h2>
      <!-- <p class="mt-1 text-sm leading-6 text-gray-400">
       Rapport détaillé tableau configuation
      </p> -->
     </div>

     <div class="md:col-span-2">
      <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
       <div class="col-span-full p-4 bg-white rounded-lg shadow-md">
        <label for="email" class="block text-sm font-semibold leading-6"
         >Nombre d’appels répondus dans un délai de :</label
        >
        <div class="rounded-md py-5">
         <p class="text-sm font-medium leading-6">
          {{ $t("timeRange") }}
         </p>
         <div class="flex items-center gap-2">
          <input
           class="w-full"
           v-model="form.initial_interval"
           type="range"
           id="vol"
           name="vol"
           min="10"
           max="140"
           step="10" />
          <span class="text-xs text-gray-500 font-medium">
           {{ form.initial_interval }}(s)
          </span>
         </div>
        </div>
        <div class="rounded-md py-5">
         <p class="text-sm font-medium leading-6">
          {{ $t("timeGap") }}
         </p>
         <div class="flex items-center gap-2">
          <input
           class="w-full"
           v-model="form.following_interval"
           type="range"
           id="vol"
           name="vol"
           min="10"
           max="40"
           step="10" />
          <span class="text-xs text-gray-500 font-medium">
           {{ form.following_interval }}(s)
          </span>
         </div>
        </div>

        <div class="rounded-md py-5">
         <p class="text-sm font-medium leading-6">
          {{ $t("columns") }}
         </p>
         <div class="flex items-center gap-2">
          <input
           class="w-full"
           v-model="form.count_interval"
           type="range"
           id="vol"
           name="vol"
           min="1"
           max="5"
           step="1" />
          <span class="text-xs text-gray-500 font-medium">
           {{ form.count_interval }}
          </span>
         </div>
        </div>
       </div>
      </div>
     </div>
     <div>
      <h2 class="text-base font-semibold leading-7">Alertes visuelles</h2>
      <!-- <p class="mt-1 text-sm leading-6 text-gray-400">
       Rapport détaillé tableau configuation
      </p> -->
     </div>

     <div class="md:col-span-2">
      <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
       <!-- Répondu % Section -->
       <div class="col-span-full p-4 bg-white rounded-lg shadow-md">
        <div class="flex justify-between items-center mb-4">
         <label class="block text-sm font-semibold leading-6">
          Taux de réponse :
         </label>
         <div>
          <button
           @click.prevent="activateAll('answered_percentage')"
           class="text-sm text-green-600 hover:underline">
           Activer tout
          </button>
          <button
           @click.prevent="deactivateAll('answered_percentage')"
           class="text-sm text-red-600 hover:underline ml-4">
           Désactiver tout
          </button>
         </div>
        </div>

        <!-- Niveau Rouge -->
        <div
         class="flex items-center justify-between py-2 border-b border-gray-200">
         <div class="flex gap-1 items-center">
          <input
           type="number"
           v-model="form.answered_percentage_red_value"
           class="form-input w-24 py-1"
           min="0"
           :max="form.answered_percentage_orange_value" />
          <span>%</span>
         </div>
         <p class="text-sm text-red-500 bg-red-200 font-semibold rounded-full px-2 py-1">
          Niveau Rouge &le; {{ form.answered_percentage_red_value }}%
         </p>
         <label class="flex items-center">
          <input
           type="checkbox"
           v-model="form.answered_percentage_red_active"
           class="form-checkbox text-red-600 h-5 w-5 rounded" />
          <!-- <span class="ml-2 text-sm text-gray-600">Activé</span> -->
         </label>
        </div>

        <!-- Niveau Orange -->
        <div
         class="flex items-center justify-between py-2 border-b border-gray-200">
         <div class="flex gap-1 items-center">
          <input
           type="number"
           v-model="form.answered_percentage_orange_value"
           class="form-input w-24 py-1"
           :min="form.answered_percentage_red_value"
           :max="form.answered_percentage_yellow_value" />
          <span>%</span>
         </div>
         <p class="text-sm text-orange-600 bg-orange-200 font-semibold rounded-full px-2 py-1">
          Niveau Orange {{ form.answered_percentage_red_value }}% &lt; % &le;
          {{ form.answered_percentage_orange_value }}%
         </p>
         <label class="flex items-center">
          <input
           type="checkbox"
           v-model="form.answered_percentage_orange_active"
           class="form-checkbox text-orange-500 h-5 w-5 rounded" />
          <!-- <span class="ml-2 text-sm text-gray-600">Activé</span> -->
         </label>
        </div>

        <!-- Niveau Jaune -->
        <div
         class="flex items-center justify-between py-2 border-b border-gray-200">
         <div class="flex gap-1 items-center">
          <input
           type="number"
           v-model="form.answered_percentage_yellow_value"
           class="form-input w-24 py-1"
           :min="form.answered_percentage_orange_value"
           :max="form.answered_percentage_green_value" />
          <span>%</span>
         </div>
         <p class="text-sm text-yellow-500 bg-yellow-100 font-semibold rounded-full px-2 py-1">
          Niveau Jaune {{ form.answered_percentage_orange_value }}% &lt; % &le;
          {{ form.answered_percentage_yellow_value }}%
         </p>
         <label class="flex items-center">
          <input
           type="checkbox"
           v-model="form.answered_percentage_yellow_active"
           class="form-checkbox text-yellow-500 h-5 w-5 rounded" />
          <!-- <span class="ml-2 text-sm text-gray-600">Activé</span> -->
         </label>
        </div>

        <!-- Niveau Vert -->
        <div class="flex items-center justify-between py-2">
         <div class="flex gap-1 items-center">
          <input
           type="number"
           v-model="form.answered_percentage_green_value"
           class="form-input w-24 py-1"
           :min="form.answered_percentage_yellow_value"
           max="100" />
          <span>%</span>
         </div>
         <p class="text-sm text-green-500 bg-green-200 font-semibold rounded-full px-2 py-1">
          Niveau Vert &gt; {{ form.answered_percentage_green_value }}%
         </p>
         <label class="flex items-center">
          <input
           type="checkbox"
           v-model="form.answered_percentage_green_active"
           class="form-checkbox text-green-500 h-5 w-5 rounded" />
          <!-- <span class="ml-2 text-sm text-gray-600">Activé</span> -->
         </label>
        </div>
       </div>

       <!-- Durée d'attente Moyenne Section -->
       <div class="col-span-full p-4 bg-white rounded-lg shadow-md">
        <div class="flex justify-between items-center mb-4">
         <label class="block text-sm font-semibold leading-6">
          Durée d'attente Moyenne :
         </label>
         <div>
          <button
           @click.prevent="activateAll('duration_avg_answer')"
           class="text-sm text-green-600 hover:underline">
           Activer tout
          </button>
          <button
           @click.prevent="deactivateAll('duration_avg_answer')"
           class="text-sm text-red-600 hover:underline ml-4">
           Désactiver tout
          </button>
         </div>
        </div>

        <!-- Niveau Rouge -->
        <div
         class="flex items-center justify-between py-2 border-b border-gray-200">
         <div class="flex gap-1 items-center">
          <input
           type="number"
           v-model="form.duration_avg_answer_red_value"
           class="form-input w-24 py-1"
           :min="form.duration_avg_answer_orange_value" />
          <span>sec</span>
         </div>
         <p class="text-sm text-red-500 bg-red-200 font-semibold rounded-full px-2 py-1">
          Niveau Rouge &gt; {{ form.duration_avg_answer_red_value }} sec
         </p>
         <label class="flex items-center">
          <input
           type="checkbox"
           v-model="form.duration_avg_answer_red_active"
           class="form-checkbox text-red-600 h-5 w-5 rounded" />
          <!-- <span class="ml-2 text-sm text-gray-600">Activé</span> -->
         </label>
        </div>

        <!-- Niveau Orange -->
        <div
         class="flex items-center justify-between py-2 border-b border-gray-200">
         <div class="flex gap-1 items-center">
          <input
           type="number"
           v-model="form.duration_avg_answer_orange_value"
           class="form-input w-24 py-1"
           :min="form.duration_avg_answer_yellow_value"
           :max="form.duration_avg_answer_red_value" />
          <span>sec</span>
         </div>
         <p class="text-sm text-orange-600 bg-orange-200 font-semibold rounded-full px-2 py-1">
          Niveau Orange {{ form.duration_avg_answer_orange_value }} sec &lt; s
          &le; {{ form.duration_avg_answer_red_value }} sec
         </p>
         <label class="flex items-center">
          <input
           type="checkbox"
           v-model="form.duration_avg_answer_orange_active"
           class="form-checkbox text-orange-500 h-5 w-5 rounded" />
          <!-- <span class="ml-2 text-sm text-gray-600">Activé</span> -->
         </label>
        </div>

        <!-- Niveau Jaune -->
        <div
         class="flex items-center justify-between py-2 border-b border-gray-200">
         <div class="flex gap-1 items-center">
          <input
           type="number"
           v-model="form.duration_avg_answer_yellow_value"
           class="form-input w-24 py-1"
           :min="form.duration_avg_answer_green_value"
           :max="form.duration_avg_answer_orange_value" />
          <span>sec</span>
         </div>
         <p class="text-sm text-yellow-500 bg-yellow-100 font-semibold rounded-full px-2 py-1">
          Niveau Jaune {{ form.duration_avg_answer_yellow_value }} sec &lt; s
          &le; {{ form.duration_avg_answer_orange_value }} sec
         </p>
         <label class="flex items-center">
          <input
           type="checkbox"
           v-model="form.duration_avg_answer_yellow_active"
           class="form-checkbox text-yellow-500 h-5 w-5 rounded" />
          <!-- <span class="ml-2 text-sm text-gray-600">Activé</span> -->
         </label>
        </div>

        <!-- Niveau Vert -->
        <div class="flex items-center justify-between py-2">
         <div class="flex gap-1 items-center">
          <input
           type="number"
           v-model="form.duration_avg_answer_green_value"
           class="form-input w-24 py-1"
           min="0"
           :max="form.duration_avg_answer_yellow_value" />
          <span>sec</span>
         </div>
         <p class="text-sm text-green-500 bg-green-200 font-semibold rounded-full px-2 py-1">
          Niveau Vert &le; {{ form.duration_avg_answer_green_value }} sec
         </p>
         <label class="flex items-center">
          <input
           type="checkbox"
           v-model="form.duration_avg_answer_green_active"
           class="form-checkbox text-green-500 h-5 w-5 rounded" />
          <!-- <span class="ml-2 text-sm text-gray-600">Activé</span> -->
         </label>
        </div>
       </div>

       <!-- Durée d'appel Moyenne Section -->
       <div class="col-span-full p-4 bg-white rounded-lg shadow-md">
        <div class="flex justify-between items-center mb-4">
         <label class="block text-sm font-semibold leading-6">
          Durée d'appel Moyenne :
         </label>
         <div>
          <button
           @click.prevent="activateAll('duration_avg_call')"
           class="text-sm text-green-600 hover:underline">
           Activer tout
          </button>
          <button
           @click.prevent="deactivateAll('duration_avg_call')"
           class="text-sm text-red-600 hover:underline ml-4">
           Désactiver tout
          </button>
         </div>
        </div>

        <!-- Niveau Rouge -->
        <div
         class="flex items-center justify-between py-2 border-b border-gray-200">
         <div class="flex gap-1 items-center">
          <input
           type="number"
           v-model="form.duration_avg_call_red_value"
           class="form-input w-24 py-1"
           :min="form.duration_avg_call_orange_value" />
          <span>sec</span>
         </div>
         <p class="text-sm text-red-500 bg-red-200 font-semibold rounded-full px-2 py-1">
          Niveau Rouge &gt; {{ form.duration_avg_call_red_value }} sec
         </p>
         <label class="flex items-center">
          <input
           type="checkbox"
           v-model="form.duration_avg_call_red_active"
           class="form-checkbox text-red-600 h-5 w-5 rounded" />
          <!-- <span class="ml-2 text-sm text-gray-600">Activé</span> -->
         </label>
        </div>

        <!-- Niveau Orange -->
        <div
         class="flex items-center justify-between py-2 border-b border-gray-200">
         <div class="flex gap-1 items-center">
          <input
           type="number"
           v-model="form.duration_avg_call_orange_value"
           class="form-input w-24 py-1"
           :min="form.duration_avg_call_yellow_value"
           :max="form.duration_avg_call_red_value" />
          <span>sec</span>
         </div>
         <p class="text-sm text-orange-600 bg-orange-200 font-semibold rounded-full px-2 py-1">
          Niveau Orange {{ form.duration_avg_call_orange_value }} sec &lt; s
          &le; {{ form.duration_avg_call_red_value }} sec
         </p>
         <label class="flex items-center">
          <input
           type="checkbox"
           v-model="form.duration_avg_call_orange_active"
           class="form-checkbox text-orange-500 h-5 w-5 rounded" />
          <!-- <span class="ml-2 text-sm text-gray-600">Activé</span> -->
         </label>
        </div>

        <!-- Niveau Jaune -->
        <div
         class="flex items-center justify-between py-2 border-b border-gray-200">
         <div class="flex gap-1 items-center">
          <input
           type="number"
           v-model="form.duration_avg_call_yellow_value"
           class="form-input w-24 py-1"
           :min="form.duration_avg_call_green_value"
           :max="form.duration_avg_call_orange_value" />
          <span>sec</span>
         </div>
         <p class="text-sm text-yellow-500 bg-yellow-100 font-semibold rounded-full px-2 py-1">
          Niveau Jaune {{ form.duration_avg_call_yellow_value }} sec &lt; s &le;
          {{ form.duration_avg_call_orange_value }} sec
         </p>
         <label class="flex items-center">
          <input
           type="checkbox"
           v-model="form.duration_avg_call_yellow_active"
           class="form-checkbox text-yellow-500 h-5 w-5 rounded" />
          <!-- <span class="ml-2 text-sm text-gray-600">Activé</span> -->
         </label>
        </div>

        <!-- Niveau Vert -->
        <div class="flex items-center justify-between py-2">
         <div class="flex gap-1 items-center">
          <input
           type="number"
           v-model="form.duration_avg_call_green_value"
           class="form-input w-24 py-1"
           min="0"
           :max="form.duration_avg_call_yellow_value" />
          <span>sec</span>
         </div>
         <p class="text-sm text-green-500 bg-green-200 font-semibold rounded-full px-2 py-1">
          Niveau Vert &le; {{ form.duration_avg_call_green_value }} sec
         </p>
         <label class="flex items-center">
          <input
           type="checkbox"
           v-model="form.duration_avg_call_green_active"
           class="form-checkbox text-green-500 h-5 w-5 rounded" />
          <!-- <span class="ml-2 text-sm text-gray-600">Activé</span> -->
         </label>
        </div>
       </div>
       <!-- Ratio Appels vs Sollicitations Section -->
       <div class="col-span-full p-4 bg-white rounded-lg shadow-md">
        <div class="flex justify-between items-center mb-4">
         <label class="block text-sm font-semibold leading-6">
          Ratio Appels vs Sollicitations :
         </label>
         <div>
          <button
           @click.prevent="activateAll('ratio_solicitations')"
           class="text-sm text-green-600 hover:underline">
           Activer tout
          </button>
          <button
           @click.prevent="deactivateAll('ratio_solicitations')"
           class="text-sm text-red-600 hover:underline ml-4">
           Désactiver tout
          </button>
         </div>
        </div>

        <!-- Niveau Rouge -->
        <div
         class="flex items-center justify-between py-2 border-b border-gray-200">
         <input
          type="number"
          step="0.01"
          v-model="form.ratio_solicitations_red_value"
          class="form-input w-24 py-1"
          :min="form.ratio_solicitations_orange_value" />
         <p class="text-sm text-red-500 bg-red-200 font-semibold rounded-full px-2 py-1">
          Niveau Rouge &gt; {{ form.ratio_solicitations_red_value }}
         </p>
         <label class="flex items-center">
          <input
           type="checkbox"
           v-model="form.ratio_solicitations_red_active"
           class="form-checkbox text-red-600 h-5 w-5 rounded" />
          <!-- <span class="ml-2 text-sm text-gray-600">Activé</span> -->
         </label>
        </div>

        <!-- Niveau Orange -->
        <div
         class="flex items-center justify-between py-2 border-b border-gray-200">
         <input
          type="number"
          step="0.01"
          v-model="form.ratio_solicitations_orange_value"
          class="form-input w-24 py-1"
          :min="form.ratio_solicitations_yellow_value"
          :max="form.ratio_solicitations_red_value" />
         <p class="text-sm text-orange-600 bg-orange-200 font-semibold rounded-full px-2 py-1">
          Niveau Orange {{ form.ratio_solicitations_orange_value }} &lt; ratio
          &le;
          {{ form.ratio_solicitations_red_value }}
         </p>
         <label class="flex items-center">
          <input
           type="checkbox"
           v-model="form.ratio_solicitations_orange_active"
           class="form-checkbox text-orange-500 h-5 w-5 rounded" />
          <!-- <span class="ml-2 text-sm text-gray-600">Activé</span> -->
         </label>
        </div>

        <!-- Niveau Jaune -->
        <div
         class="flex items-center justify-between py-2 border-b border-gray-200">
         <input
          type="number"
          step="0.01"
          v-model="form.ratio_solicitations_yellow_value"
          class="form-input w-24 py-1"
          :min="form.ratio_solicitations_green_value"
          :max="form.ratio_solicitations_orange_value" />
         <p class="text-sm text-yellow-500 bg-yellow-100 font-semibold rounded-full px-2 py-1">
          Niveau Jaune {{ form.ratio_solicitations_yellow_value }} &lt; ratio
          &le;
          {{ form.ratio_solicitations_orange_value }}
         </p>
         <label class="flex items-center">
          <input
           type="checkbox"
           v-model="form.ratio_solicitations_yellow_active"
           class="form-checkbox text-yellow-500 h-5 w-5 rounded" />
          <!-- <span class="ml-2 text-sm text-gray-600">Activé</span> -->
         </label>
        </div>

        <!-- Niveau Vert -->
        <div class="flex items-center justify-between py-2">
         <input
          type="number"
          step="0.01"
          v-model="form.ratio_solicitations_green_value"
          class="form-input w-24 py-1"
          min="0"
          :max="form.ratio_solicitations_yellow_value" />
         <p class="text-sm text-green-500 bg-green-200 font-semibold rounded-full px-2 py-1">
          Niveau Vert &le; {{ form.ratio_solicitations_green_value }}
         </p>
         <label class="flex items-center">
          <input
           type="checkbox"
           v-model="form.ratio_solicitations_green_active"
           class="form-checkbox text-green-500 h-5 w-5 rounded" />
          <!-- <span class="ml-2 text-sm text-gray-600">Activé</span> -->
         </label>
        </div>
       </div>
      </div>
     </div>
    </div>
    <div class="mt-8 flex justify-end">
     <button
      v-if="menu !== 'groups'"
      type="submit"
      class="capitalize w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 body__header--button text-base font-medium text-white sm:w-auto sm:text-sm">
      {{ $t("validate") }}
     </button>
    </div>
   </form>
  </div>
 </div>
</template>

<script>
import PageHeading from "../../components/PageHeading.vue";
import axios from "axios";
import { mapGetters } from "vuex";
const secondaryNavigation = [
 { name: "Groupes", href: "groups", current: false },
 { name: "report detail", href: "report-table", current: false },
 { name: "pbx host", href: "pbx", current: false },
];
const navigation_groups = [
 { name: "numéros", href: "did" },
 { name: "files", href: "queue" },
 { name: "utilisateurs", href: "extension" },
];
export default {
 props: ["menu"],
 components: {
  PageHeading,
 },
 data() {
  return {
   navigation_groups,
   secondaryNavigation,
   form: {
    host_name: null,
    initial_interval: 20,
    following_interval: 20,
    count_interval: 4,
    // Répondu % Values
    answered_percentage_red_value: 0,
    answered_percentage_orange_value: 60,
    answered_percentage_yellow_value: 70,
    answered_percentage_green_value: 80,
    answered_percentage_red_active: false,
    answered_percentage_orange_active: false,
    answered_percentage_yellow_active: false,
    answered_percentage_green_active: false,

    // Durée d'attente Moyenne Values
    duration_avg_answer_red_value: 60,
    duration_avg_answer_orange_value: 50,
    duration_avg_answer_yellow_value: 40,
    duration_avg_answer_green_value: 30,
    duration_avg_answer_red_active: false,
    duration_avg_answer_orange_active: false,
    duration_avg_answer_yellow_active: false,
    duration_avg_answer_green_active: false,

    // Durée d'appel Moyenne Values
    duration_avg_call_red_value: 60,
    duration_avg_call_orange_value: 50,
    duration_avg_call_yellow_value: 40,
    duration_avg_call_green_value: 30,
    duration_avg_call_red_active: false,
    duration_avg_call_orange_active: false,
    duration_avg_call_yellow_active: false,
    duration_avg_call_green_active: false,

    // Ratio Appels vs Sollicitations Values
    ratio_solicitations_red_value: 1.5,
    ratio_solicitations_orange_value: 1.25,
    ratio_solicitations_yellow_value: 1.1,
    ratio_solicitations_green_value: 1.0,
    ratio_solicitations_red_active: false,
    ratio_solicitations_orange_active: false,
    ratio_solicitations_yellow_active: false,
    ratio_solicitations_green_active: false,
   },
  };
 },
 methods: {
  activateAll(section) {
   if (section === "answered_percentage") {
    this.form.answered_percentage_red_active = true;
    this.form.answered_percentage_orange_active = true;
    this.form.answered_percentage_yellow_active = true;
    this.form.answered_percentage_green_active = true;
   } else if (section === "duration_avg_answer") {
    this.form.duration_avg_answer_red_active = true;
    this.form.duration_avg_answer_orange_active = true;
    this.form.duration_avg_answer_yellow_active = true;
    this.form.duration_avg_answer_green_active = true;
   } else if (section === "duration_avg_call") {
    this.form.duration_avg_call_red_active = true;
    this.form.duration_avg_call_orange_active = true;
    this.form.duration_avg_call_yellow_active = true;
    this.form.duration_avg_call_green_active = true;
   } else if (section === "ratio_solicitations") {
    this.form.ratio_solicitations_red_active = true;
    this.form.ratio_solicitations_orange_active = true;
    this.form.ratio_solicitations_yellow_active = true;
    this.form.ratio_solicitations_green_active = true;
   }
  },
  deactivateAll(section) {
   if (section === "answered_percentage") {
    this.form.answered_percentage_red_active = false;
    this.form.answered_percentage_orange_active = false;
    this.form.answered_percentage_yellow_active = false;
    this.form.answered_percentage_green_active = false;
   } else if (section === "duration_avg_answer") {
    this.form.duration_avg_answer_red_active = false;
    this.form.duration_avg_answer_orange_active = false;
    this.form.duration_avg_answer_yellow_active = false;
    this.form.duration_avg_answer_green_active = false;
   } else if (section === "duration_avg_call") {
    this.form.duration_avg_call_red_active = false;
    this.form.duration_avg_call_orange_active = false;
    this.form.duration_avg_call_yellow_active = false;
    this.form.duration_avg_call_green_active = false;
   } else if (section === "ratio_solicitations") {
    this.form.ratio_solicitations_red_active = false;
    this.form.ratio_solicitations_orange_active = false;
    this.form.ratio_solicitations_yellow_active = false;
    this.form.ratio_solicitations_green_active = false;
   }
  },
  getConfigurationValues() {
   let data = this.pbx3cxTableConfig;

   if (data) {
    this.form.host_name = data?.host_name;
    this.form.initial_interval = data?.initial_interval;
    this.form.following_interval = data?.following_interval;
    this.form.count_interval = data?.count_interval;
    this.form.answered_percentage_red_active =
     data?.answered_percentage_red_active > 0 ? true : false;
    this.form.answered_percentage_orange_active =
     data?.answered_percentage_orange_active > 0 ? true : false;
    this.form.answered_percentage_yellow_active =
     data?.answered_percentage_yellow_active > 0 ? true : false;
    this.form.answered_percentage_green_active =
     data?.answered_percentage_green_active > 0 ? true : false;
    this.form.duration_avg_answer_red_active =
     data?.duration_avg_answer_red_active > 0 ? true : false;
    this.form.duration_avg_answer_orange_active =
     data?.duration_avg_answer_orange_active > 0 ? true : false;
    this.form.duration_avg_answer_yellow_active =
     data?.duration_avg_answer_yellow_active > 0 ? true : false;
    this.form.duration_avg_answer_green_active =
     data?.duration_avg_answer_green_active > 0 ? true : false;
    this.form.duration_avg_call_red_active =
     data?.duration_avg_call_red_active > 0 ? true : false;
    this.form.duration_avg_call_orange_active =
     data?.duration_avg_call_orange_active > 0 ? true : false;
    this.form.duration_avg_call_yellow_active =
     data?.duration_avg_call_yellow_active > 0 ? true : false;
    this.form.duration_avg_call_green_active =
     data?.duration_avg_call_green_active > 0 ? true : false;
    this.form.ratio_solicitations_red_active =
     data?.ratio_solicitations_red_active > 0 ? true : false;
    this.form.ratio_solicitations_orange_active =
     data?.ratio_solicitations_orange_active > 0 ? true : false;
    this.form.ratio_solicitations_yellow_active =
     data?.ratio_solicitations_yellow_active > 0 ? true : false;
    this.form.ratio_solicitations_green_active =
     data?.ratio_solicitations_green_active > 0 ? true : false;

    this.form.answered_percentage_red_value =
     data?.answered_percentage_red_value;
    this.form.answered_percentage_orange_value =
     data?.answered_percentage_orange_value;
    this.form.answered_percentage_yellow_value =
     data?.answered_percentage_yellow_value;
    this.form.answered_percentage_green_value =
     data?.answered_percentage_green_value;
    this.form.duration_avg_answer_red_value =
     data?.duration_avg_answer_red_value;
    this.form.duration_avg_answer_orange_value =
     data?.duration_avg_answer_orange_value;
    this.form.duration_avg_answer_yellow_value =
     data?.duration_avg_answer_yellow_value;
    this.form.duration_avg_answer_green_value =
     data?.duration_avg_answer_green_value;
    this.form.duration_avg_call_red_value = data?.duration_avg_call_red_value;
    this.form.duration_avg_call_orange_value =
     data?.duration_avg_call_orange_value;
    this.form.duration_avg_call_yellow_value =
     data?.duration_avg_call_yellow_value;
    this.form.duration_avg_call_green_value =
     data?.duration_avg_call_green_value;
    this.form.ratio_solicitations_red_value =
     data?.ratio_solicitations_red_value;
    this.form.ratio_solicitations_orange_value =
     data?.ratio_solicitations_orange_value;
    this.form.ratio_solicitations_yellow_value =
     data?.ratio_solicitations_yellow_value;
    this.form.ratio_solicitations_green_value =
     data?.ratio_solicitations_green_value;
   }
   if (!this.form.host_name && this.hostName)
    this.form.host_name = this.hostName;
  },
  sendForm() {
   const url = `${localStorage.getItem(
    "apiUrl"
   )}/api/v1.2/pbx3cx-report-table-configurations`;
   const urlPut = `${localStorage.getItem(
    "apiUrl"
   )}/api/v1.2/pbx3cx-report-table-configurations/${this.pbx3cxCustomerCode}`;
   if (this.pbx3cxCustomerCode) {
    if (this.pbx3cxTableConfig) {
     axios
      .put(urlPut, this.form, {
       headers: {
        Authorization: "Bearer " + localStorage.getItem("apiToken"),
       },
      })
      .then(res => {
       this.$store.dispatch("hostName", res.data?.host_name);
       localStorage.setItem("hostName", res.data?.host_name);
       this.$store.dispatch("pbx3cxTableConfig", res.data);
       this.$emit("activeBanner");
       this.$emit("successMsg", this.$t("successMsg"));
       this.$emit("success", true);
      })
      .catch(err => {
       this.errorHandling(err);
      });
    } else {
     this.form.customer_code = this.pbx3cxCustomerCode;
     axios
      .post(url, this.form, {
       headers: {
        Authorization: "Bearer " + localStorage.getItem("apiToken"),
       },
      })
      .then(res => {
       this.$store.dispatch("hostName", res.data?.host_name);
       localStorage.setItem("hostName", res.data?.host_name);
       this.$store.dispatch("pbx3cxTableConfig", res.data);
       this.$emit("activeBanner");
       this.$emit("successMsg", this.$t("successMsg"));
       this.$emit("success", true);
      })
      .catch(err => {
       this.errorHandling(err);
      });
    }
   } else {
    this.$emit("activeBanner");
    this.$emit("successMsg", this.$t("onlyAdminMsg"));
    this.$emit("success", false);
   }
  },
 },
 mounted() {
  this.getConfigurationValues();
 },
 computed: {
  ...mapGetters([
   "hostNames",
   "hostName",
   "user",
   "pbx3cxCustomerCode",
   "pbx3cxTableConfig",
  ]),
  navigation() {
   return this.secondaryNavigation.map(nav => {
    nav.href === this.menu ? (nav.current = true) : (nav.current = false);
    return nav;
   });
  },
 },
 watch: {
  pbx3cxTableConfig: function (val) {
   this.form.host_name = val?.host_name;
   this.form.initial_interval = val?.initial_interval;
   this.form.following_interval = val?.following_interval;
   this.form.count_interval = val?.count_interval;
   this.form.answered_percentage_red_active =
    val?.answered_percentage_red_active > 0 ? true : false;
   this.form.answered_percentage_orange_active =
    val?.answered_percentage_orange_active > 0 ? true : false;
   this.form.answered_percentage_yellow_active =
    val?.answered_percentage_yellow_active > 0 ? true : false;
   this.form.answered_percentage_green_active =
    val?.answered_percentage_green_active > 0 ? true : false;
   this.form.duration_avg_answer_red_active =
    val?.duration_avg_answer_red_active > 0 ? true : false;
   this.form.duration_avg_answer_orange_active =
    val?.duration_avg_answer_orange_active > 0 ? true : false;
   this.form.duration_avg_answer_yellow_active =
    val?.duration_avg_answer_yellow_active > 0 ? true : false;
   this.form.duration_avg_answer_green_active =
    val?.duration_avg_answer_green_active > 0 ? true : false;
   this.form.duration_avg_call_red_active =
    val?.duration_avg_call_red_active > 0 ? true : false;
   this.form.duration_avg_call_orange_active =
    val?.duration_avg_call_orange_active > 0 ? true : false;
   this.form.duration_avg_call_yellow_active =
    val?.duration_avg_call_yellow_active > 0 ? true : false;
   this.form.duration_avg_call_green_active =
    val?.duration_avg_call_green_active > 0 ? true : false;
   this.form.ratio_solicitations_red_active =
    val?.ratio_solicitations_red_active > 0 ? true : false;
   this.form.ratio_solicitations_orange_active =
    val?.ratio_solicitations_orange_active > 0 ? true : false;
   this.form.ratio_solicitations_yellow_active =
    val?.ratio_solicitations_yellow_active > 0 ? true : false;
   this.form.ratio_solicitations_green_active =
    val?.ratio_solicitations_green_active > 0 ? true : false;

   this.form.answered_percentage_red_value = val?.answered_percentage_red_value;
   this.form.answered_percentage_orange_value =
    val?.answered_percentage_orange_value;
   this.form.answered_percentage_yellow_value =
    val?.answered_percentage_yellow_value;
   this.form.answered_percentage_green_value =
    val?.answered_percentage_green_value;
   this.form.duration_avg_answer_red_value = val?.duration_avg_answer_red_value;
   this.form.duration_avg_answer_orange_value =
    val?.duration_avg_answer_orange_value;
   this.form.duration_avg_answer_yellow_value =
    val?.duration_avg_answer_yellow_value;
   this.form.duration_avg_answer_green_value =
    val?.duration_avg_answer_green_value;
   this.form.duration_avg_call_red_value = val?.duration_avg_call_red_value;
   this.form.duration_avg_call_orange_value =
    val?.duration_avg_call_orange_value;
   this.form.duration_avg_call_yellow_value =
    val?.duration_avg_call_yellow_value;
   this.form.duration_avg_call_green_value = val?.duration_avg_call_green_value;
   this.form.ratio_solicitations_red_value = val?.ratio_solicitations_red_value;
   this.form.ratio_solicitations_orange_value =
    val?.ratio_solicitations_orange_value;
   this.form.ratio_solicitations_yellow_value =
    val?.ratio_solicitations_yellow_value;
   this.form.ratio_solicitations_green_value =
    val?.ratio_solicitations_green_value;
  },
 },
};
</script>

<style></style>
